<template>
  <search-result-section
    v-if="Boolean(query)"
    :title="$t('SEARCH.SECTION.CONVERSATIONS')"
    :empty="!conversations.length"
    :query="query"
    :show-title="showTitle || isFetching"
    :is-fetching="isFetching"
  >
    <ul v-if="conversations.length" class="search-list">
      <li v-for="conversation in conversations" :key="conversation.id">
        <search-result-conversation-item
          :id="conversation.id"
          :name="conversation.contact.name"
          :email="conversation.contact.email"
          :account-id="accountId"
          :inbox="conversation.inbox"
          :created-at="conversation.created_at"
          :has-checkbox="hasCheckbox"
          :value="selections.includes(conversation.id)"
          :message-id="conversation.message.id"
          @input="toggleCheck(conversation.id, $event)"
        >
          <message-content
            v-if="hasMessageContent"
            :author="getName(conversation.message)"
            :content="conversation.message.content"
            :search-term="query"
            :is-use-readmore="isUseReadmore"
          />
        </search-result-conversation-item>
      </li>
    </ul>
  </search-result-section>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchResultSection from './SearchResultSection.vue';
import SearchResultConversationItem from './SearchResultConversationItem.vue';
import MessageContent from './MessageContent.vue';

export default {
  components: {
    SearchResultSection,
    SearchResultConversationItem,
    MessageContent,
  },
  props: {
    conversations: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: '',
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    selections: {
      type: Array,
      default: () => [],
    },
    isUseReadmore: {
      type: Boolean,
      default: true,
    },
    hasMessageContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkedIds: [],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    getName(message) {
      return message && message.sender && message.sender.name
        ? message.sender.name
        : this.$t('SEARCH.BOT_LABEL');
    },
    toggleCheck(id, checked) {
      if (checked) {
        if (!this.checkedIds.includes(id)) {
          this.checkedIds.push(id);
        }
      } else {
        const index = this.checkedIds.indexOf(id);
        if (index > -1) {
          this.checkedIds.splice(index, 1);
        }
      }
      this.$emit('selection-change', this.checkedIds);
    },
  },
};
</script>
