<template>
  <div
    class="secondary-panel w-[220px]"
    :class="{ 'overflow-y-hidden': isSidebarScrollable }"
  >
    <new-account-dropdown
      :is-sidebar-expanded="true"
      :logo-source="logoSource"
      :name="installationName"
      :account-id="accountId"
      :primary-menu-items="primaryMenuItems"
      @show-create-account-modal="$emit('show-create-account-modal')"
      @sidebar-scrollable="val => scrollableSidebar(val)"
    />

    <!-- back to homepage -->
    <transition name="menu-list" class="pt-2 list-none ml-0 mb-0 flex-1">
      <div @click="handleBackToDashboard">
        <div class="sidebar-action">
          <div class="sidebar-action__icon">
            <fluent-icon size="16" icon="chevron-left" type="outline" />
          </div>
          <div class="sidebar-action__text">Help center</div>
        </div>
      </div>
    </transition>

    <!-- initial menu if no portals -->
    <transition-group
      v-if="!hasPortal"
      tag="ul"
      name="menu-list"
      class="pt-2 list-none ml-0 mb-0 flex-1"
    >
      <new-secondary-nav-item
        v-for="menuItem in initialMenuItems"
        :key="menuItem.toState"
        :is-sidebar-expanded="true"
        :menu-item="menuItem"
      />
    </transition-group>

    <!-- portal chooser -->
    <sidebar-header
      v-if="hasPortal"
      :thumbnail-src="thumbnailSrc"
      :header-title="headerTitle"
      :sub-title="subTitle"
      :portal-link="portalLink"
      @open-popover="openPortalPopover"
    />

    <!-- sidebar menu if has portal -->
    <transition-group
      v-if="hasPortal"
      name="menu-list"
      tag="ul"
      class="list-none ml-0 mb-0 flex-1"
    >
      <new-secondary-nav-item
        v-for="menuItem in accessibleMenuItems"
        :key="menuItem.toState"
        :is-sidebar-expanded="true"
        :menu-item="menuItem"
      />
      <new-secondary-nav-item
        v-for="menuItem in additionalSecondaryMenuItems"
        :key="menuItem.key"
        :is-sidebar-expanded="true"
        :menu-item="menuItem"
        @add-category="onClickOpenAddCatogoryModal"
      />
    </transition-group>

    <!-- bottom section -->
    <new-notification-bell
      :is-sidebar-expanded="true"
      @open-notification-panel="openNotificationPanel"
    />
    <help-menu :is-sidebar-expanded="true" />
    <new-agent-details
      :is-sidebar-expanded="true"
      @toggle-menu="toggleOptions"
    />
    <options-menu
      :is-has-onboarding="!hideOnboarding"
      :is-sidebar-expanded="true"
      :show="showOptionsMenu"
      @toggle-accounts="toggleAccountModal"
      @key-shortcut-modal="$emit('key-shortcut-modal')"
      @close="toggleOptions"
    />
    <new-onboarding-view v-if="!hideOnboarding" :is-sidebar-expanded="true" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NewSecondaryNavItem from 'dashboard/components/layout/sidebarComponents/NewSecondaryNavItem.vue';
import SidebarHeader from './SidebarHeader.vue';
import NewAccountDropdown from 'dashboard/components/layout/sidebarComponents/NewAccountDropdown.vue';
import NewOnboardingView from 'dashboard/components/NewOnboardingView';
import { ACCOUNT_EVENTS } from 'dashboard/helper/AnalyticsHelper/events';
import NewNotificationBell from 'dashboard/components/layout/sidebarComponents/NewNotificationBell.vue';
import HelpMenu from 'dashboard/components/layout/sidebarComponents/HelpMenu.vue';
import NewAgentDetails from 'dashboard/components/layout/sidebarComponents/NewAgentDetails.vue';
import OptionsMenu from 'dashboard/components/layout/sidebarComponents/OptionsMenu.vue';

export default {
  components: {
    NewSecondaryNavItem,
    SidebarHeader,
    NewAccountDropdown,
    NewOnboardingView,
    NewNotificationBell,
    HelpMenu,
    NewAgentDetails,
    OptionsMenu,
  },
  props: {
    thumbnailSrc: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    portalSlug: {
      type: String,
      default: '',
    },
    localeSlug: {
      type: String,
      default: '',
    },
    initialMenuItems: {
      type: Array,
      default: () => [],
    },
    accessibleMenuItems: {
      type: Array,
      default: () => [],
    },
    additionalSecondaryMenuItems: {
      type: Array,
      default: () => [],
    },
    hasPortal: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    primaryMenuItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showOptionsMenu: false,
      isSidebarScrollable: true,
    };
  },
  computed: {
    ...mapGetters({
      currentAccount: 'getCurrentAccount',
    }),
    portalLink() {
      return `/hc/${this.portalSlug}/${this.localeSlug}`;
    },
    hideOnboarding() {
      if (!this.currentAccount) return true;
      return this.currentAccount.hide_onboarding;
    },
  },
  methods: {
    onSearch(value) {
      this.$emit('input', value);
    },
    openPortalPopover() {
      this.$emit('open-popover');
    },
    onClickOpenAddCatogoryModal() {
      this.$emit('add-category');
    },
    handleBackToDashboard() {
      this.$router.push({
        name: 'home',
      });
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    scrollableSidebar(val) {
      this.isSidebarScrollable = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-panel {
  @apply h-full overflow-auto flex flex-col bg-neutral-subtle dark:bg-primary border-r rtl:border-r-0 rtl:border-l text-sm pb-4 border-neutral-medium dark:border-neutral-dark-medium overflow-x-hidden px-2;
}

.sidebar-action {
  @apply flex
  items-center
  rounded-[4px]
  leading-4
  font-medium
  p-2
  m-0
  h-[33px]
  text-sm
  mt-4
  text-secondary
  dark:text-secondary
  hover:text-secondary
  hover:bg-neutral-medium
  dark:hover:bg-[#222527]
  border
  border-transparent
  cursor-pointer;

  &__icon {
    @apply w-[16px] h-[16px] relative mr-1.5 rtl:mr-0 rtl:ml-1.5;
  }

  &__text {
    @apply leading-none;
  }
}
</style>
