<template>
  <div class="woot-modal-header flex flex-col items-start pt-5 px-8 pb-0">
    <div class="flex items-center w-full">
      <woot-button
        v-if="showBackButton"
        size="tiny"
        variant="smooth"
        color-scheme="secondary"
        icon="chevron-left"
        class="mr-3 h-[1.5rem]"
        @click="onClickBackButton"
      />
      <div
        ref="modalHeaderTitle"
        class="font-medium text-base text-primary dark:text-primary-dark"
      >
        {{ headerTitle }}
      </div>
    </div>
    <p
      v-if="headerContent"
      ref="modalHeaderContent"
      class="w-full break-words text-secondary dark:text-secondary-dark text-sm"
    >
      {{ headerContent }}
      <span
        v-if="headerContentValue"
        class="font-semibold text-sm text-secondary dark:text-secondary-dark"
      >
        {{ headerContentValue }}
      </span>
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerContentValue: {
      type: String,
      default: '',
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickBackButton() {
      this.$emit('back');
    },
  },
};
</script>
