<template>
  <div class="search-page">
    <div class="text-sm mb-4 font-medium">
      {{ $t('CONVERSATION.MERGE_MODAL.PRIMARY') }}
    </div>
    <search-result-conversation-item
      :id="currentChat.id"
      class="primary-conversation"
      :inbox="getInboxData"
      :name="currentChat.meta.sender.name"
      :email="currentChat.meta.sender.email"
      :account-id="accountId"
      :created-at="currentChat.created_at"
      is-item-as-trigger
      has-message-content
    >
      <message-content
        :author="currentChat.meta.sender.name"
        :content="currentChat.messages[0].content"
        :search-term="query"
        :is-use-readmore="false"
      />
    </search-result-conversation-item>
    <section
      class="search-root"
      :class="{ 'search-root--fluid': isFluidWidth }"
    >
      <header v-show="showSearchInput">
        <div class="text-sm mt-2 mb-4 font-medium">
          {{ $t('CONVERSATION.MERGE_MODAL.SEARCH_LABEL') }}
        </div>
        <search-header :placeholder="searchPlaceholder" @search="onSearch" />
      </header>
      <div v-if="!showSearchInput" class="text-sm mt-4 font-medium">
        {{ $t('CONVERSATION.MERGE_MODAL.SECONDARY') }}
      </div>
      <div class="search-results">
        <div v-if="showResultsSection">
          <search-result-conversations-list
            :is-fetching="uiFlags.conversation.isFetching"
            :conversations="showSearchInput ? conversations : filteredData"
            :query="query"
            :show-title="false"
            has-checkbox
            :is-use-readmore="false"
            has-message-content
            @selection-change="updateSecondarySelections"
          />
        </div>
        <div v-else-if="showEmptySearchResults" class="empty">
          <fluent-icon icon="info" size="16px" class="icon" />
          <p class="empty-state__text">
            {{ $t('SEARCH.EMPTY_STATE_FULL', { query }) }}
          </p>
        </div>
        <div v-else class="empty text-center">
          <p class="text-center margin-bottom-0">
            <fluent-icon icon="search" size="24px" class="icon" />
          </p>
          <p class="empty-state__text">
            {{ emptyStateMessage }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SearchHeader from './SearchHeader.vue';
import SearchResultConversationsList from './SearchResultConversationsList.vue';
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import { CONVERSATION_EVENTS } from '../../../helper/AnalyticsHelper/events';
import MessageContent from './MessageContent.vue';
import SearchResultConversationItem from './SearchResultConversationItem.vue';

export default {
  components: {
    SearchHeader,
    SearchResultConversationsList,
    MessageContent,
    SearchResultConversationItem,
  },
  mixins: [clickaway],
  props: {
    isFluidWidth: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default() {
        return this.$t('SEARCH.INPUT_PLACEHOLDER');
      },
    },
    emptyStateMessage: {
      type: String,
      default() {
        return this.$t('SEARCH.EMPTY_STATE_DEFAULT');
      },
    },
    showSearchInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      query: undefined,
      secondarySelections: [],
      selections: [],
      filteredData: [],
    };
  },

  computed: {
    ...mapGetters({
      conversationRecords: 'conversationSearch/getConversationRecords',
      uiFlags: 'conversationSearch/getUIFlags',
      currentChat: 'getSelectedChat',
      accountId: 'getCurrentAccountId',
      inbox: 'inboxes/getInbox',
    }),
    conversations() {
      return this.conversationRecords.map(conversation => ({
        ...conversation,
        type: 'conversation',
      }));
    },
    totalSearchResultsCount() {
      return this.conversations.length;
    },
    showEmptySearchResults() {
      return (
        this.totalSearchResultsCount === 0 &&
        this.uiFlags.isSearchCompleted &&
        !this.uiFlags.isFetching &&
        this.query
      );
    },
    showResultsSection() {
      return (
        (this.uiFlags.isSearchCompleted &&
          this.totalSearchResultsCount !== 0) ||
        this.uiFlags.isFetching
      );
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    currentInbox() {
      return this.inbox(this.inboxId);
    },
    getInboxData() {
      const { id, channel_id, name, channel_type } = this.currentInbox;
      return { id, channel_id, name, channel_type };
    },
  },
  watch: {
    query(newValue) {
      if (!newValue.trim()) {
        this.clearAllSelections();
      }
    },
  },
  beforeDestroy() {
    this.query = '';
    this.$store.dispatch('conversationSearch/clearSearchResults');
  },
  mounted() {
    this.$store.dispatch('conversationSearch/clearSearchResults');
  },
  methods: {
    onSearch(q) {
      this.query = q;
      if (!q) {
        this.$store.dispatch('conversationSearch/clearSearchResults');
        return;
      }
      this.$track(CONVERSATION_EVENTS.SEARCH_CONVERSATION);
      this.$store.dispatch('conversationSearch/mergeSearch', {
        q,
        primaryId: this.currentChat.id,
      });
    },
    updateSecondarySelections(selectedIds) {
      this.secondarySelections = selectedIds;
      this.combineSelections();
    },
    combineSelections() {
      this.selections = {
        primaryId: this.currentChat.id,
        secondaryId: this.secondarySelections,
      };
      this.filteredSecondaryConversations();
    },
    clearAllSelections() {
      this.selections = [];
      this.secondarySelections = [];
    },
    filteredSecondaryConversations() {
      // filter conversation based on selected secondaryId
      this.filteredData = this.conversations.filter(res =>
        this.selections?.secondaryId?.includes(res.id)
      );

      // checked-data is for interaction (on review, disabled button, etc)
      this.$emit('checked-data', this.selections.secondaryId);

      // submitted-data is for the data that will be sent to the backend
      this.$emit('submitted-data', this.selections);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-page {
  @apply flex flex-col w-full bg-transparent;
}
.page-header {
  @apply flex p-4;
}
.search-root {
  @apply flex my-0 relative mx-auto flex-col w-full h-full max-w-[45rem] min-h-[20rem] bg-transparent;

  .search-results {
    @apply flex-grow h-full overflow-y-auto py-0;
  }

  &--fluid {
    @apply max-w-[55rem];
  }
}

.empty {
  @apply flex flex-col items-center justify-center py-6 px-4 rounded-md mt-8 max-w-[450px] m-auto;
  .icon {
    @apply text-secondary dark:text-secondary-dark;
  }
  .empty-state__text {
    @apply text-center text-secondary dark:text-secondary-dark m-2;
  }
}

.primary-conversation {
  @apply mb-1;

  ::v-deep {
    .conversation-item {
      @apply cursor-default;
    }
  }
}
</style>
