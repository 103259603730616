<template>
  <woot-modal
    :show.sync="showModal"
    :on-close="onCancel"
    :is-use-transition="false"
    modal-type="right-aligned"
    class="mergeconv"
  >
    <div class="mergeconv__wrapper">
      <div class="mergeconv__head">
        <woot-modal-header
          :show-back-button="showBackButton"
          class="pt-4"
          :header-title="
            showBackButton
              ? $t('CONVERSATION.MERGE_MODAL.REVIEW_TITLE')
              : $t('CONVERSATION.MERGE_MODAL.TITLE')
          "
          @back="onClickBackButton"
        />
      </div>
      <div ref="mergeModalEl" class="mergeconv__body">
        <template v-if="hasMessageContent">
          <merge-search-view
            is-fluid-width
            :show-search-input="showSearchInput"
            :search-placeholder="
              $t('CONVERSATION.MERGE_MODAL.SEARCH_PLACEHOLDER')
            "
            :empty-state-message="
              $t('CONVERSATION.MERGE_MODAL.SEARCH_PLACEHOLDER')
            "
            @checked-data="data => getCheckedData(data)"
            @submitted-data="data => getSubmittedData(data)"
          />
        </template>
        <template v-else>
          <div
            class="h-full text-sm text-primary dark:text-primary-dark flex items-center justify-center"
          >
            {{ $t('CONVERSATION.MERGE_MODAL.NO_MESSAGES') }}
          </div>
        </template>
      </div>
      <div v-if="hasMessageContent" class="mergeconv__footer">
        <div class="mergeconv__footer-button">
          <woot-button variant="clear" @click="onCancel">
            {{ $t('CONVERSATION.MERGE_MODAL.CANCEL') }}
          </woot-button>
        </div>
        <div class="mergeconv__footer-button">
          <woot-button
            v-if="!showBackButton"
            :is-disabled="isBtnDisabled"
            @click="onReview"
          >
            {{ $t('CONVERSATION.MERGE_MODAL.MERGE') }}
          </woot-button>
          <woot-button v-else :is-disabled="isBtnDisabled" @click="onMerge">
            {{ $t('CONVERSATION.MERGE_MODAL.MERGE') }}
          </woot-button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import MergeSearchView from 'dashboard/modules/search/components/MergeSearchView.vue';
export default {
  components: {
    MergeSearchView,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: this.show,
      checkedData: [],
      submittedData: {},
      isOnReview: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    hasCheckedData() {
      return this.checkedData.length !== 0;
    },
    showBackButton() {
      return this.isOnReview && this.hasCheckedData;
    },
    showSearchInput() {
      return !this.isOnReview || !this.hasCheckedData;
    },
    isBtnDisabled() {
      return !this.hasCheckedData;
    },
    hasMessageContent() {
      return (
        this.currentChat.messages.length > 0 &&
        !!this.currentChat.messages[0].content
      );
    },
  },
  watch: {
    async show(newVal) {
      this.showModal = newVal;

      if (newVal) {
        this.isOnReview = false;
      }
    },
    hasCheckedData(newVal) {
      if (!newVal) {
        this.isOnReview = false;
      }
    },
  },
  mounted() {},
  methods: {
    getCheckedData(data) {
      this.checkedData = data;
    },
    getSubmittedData(data) {
      this.submittedData = data;
    },
    onCancel() {
      this.$emit('cancel');
    },
    onReview() {
      this.isOnReview = true;
      this.scrollToTop();
    },
    onMerge() {
      this.$emit('merge', this.submittedData);
    },
    onClickBackButton() {
      this.isOnReview = false;
    },
    scrollToTop() {
      const modalElement = this.$refs.mergeModalEl;
      if (modalElement) {
        modalElement.scrollTop = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mergeconv {
  ::v-deep {
    .icon {
      margin-right: 0 !important;
    }
    .button {
      @apply top-[10px];
    }
  }

  &__wrapper {
    @apply h-full flex flex-col;
  }

  &__head {
    @apply border-b border-neutral-high dark:border-secondary-dark-low pb-3;
  }

  &__body {
    @apply w-full flex-1 pt-4 px-4 pb-4 overflow-y-auto;
  }

  &__footer {
    @apply flex items-center justify-end px-8 py-4 flex-shrink-0;

    &-button {
      @apply mr-2;

      &:last-child {
        @apply mr-0;
      }
    }
  }
}
</style>
