<template>
  <div id="tawkto-container" />
</template>

<script>
export default {
  name: 'TawkToChat',
  props: {
    propertyId: {
      type: String,
      required: true,
    },
    widgetId: {
      type: String,
      required: true,
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    this.loadTawkTo();
  },

  methods: {
    loadTawkTo() {
      window.Tawk_API = window.Tawk_API || {};

      // Create script element
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://embed.tawk.to/${this.propertyId}/${this.widgetId}`;
      script.setAttribute('crossorigin', '*');

      // Add script to document
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);

      // Set visitor and attributesafter script loads
      window.Tawk_API.onLoad = () => {
        if (window.Tawk_API.setAttributes) {
          // set visitor name
          window.Tawk_API.visitor = {
            name: this.userData?.agentname || '',
          };
          // get attributes data
          window.Tawk_API.setAttributes(
            {
              email: this.userData?.email || '',
              agentname: this.userData?.agentname || '',
              admin: this.userData?.admin || false,
              accountname: this.userData?.accountname || '',
            },
            error => {
              if (error) {
                // eslint-disable-next-line no-console
                console.error('Tawk.to attribute error:', error);
              }
            }
          );
        }
      };
    },
  },
};
</script>
