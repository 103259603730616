<template>
  <div
    ref="parentEl"
    class="account-dropdown"
    :class="{ '!relative': isFirefox }"
    @click="handleShowDropdown"
  >
    <div class="account-dropdown__head">
      <new-logo
        :class="
          isSidebarExpanded
            ? 'account-dropdown__head-logo-expanded'
            : 'account-dropdown__head-logo'
        "
        :source="logoSource"
        :name="installationName"
        :account-id="accountId"
      />
      <template v-if="isSidebarExpanded">
        <div class="account-dropdown__head-text">
          {{ currentStoreName }}
        </div>
        <div class="account-dropdown__head-icon">
          <fluent-icon
            v-if="isShowDropdown"
            size="16"
            icon="chevron-up"
            type="outline"
          />
          <fluent-icon v-else size="16" icon="chevron-down" type="outline" />
        </div>
      </template>
    </div>

    <!-- primary menu dropdown -->
    <div
      v-if="isShowDropdown"
      class="account-dropdown__wrapper"
      :style="dropdownStyle"
    >
      <div class="account-dropdown__title">Menus</div>
      <div class="account-dropdown__body">
        <new-primary-nav-item
          v-for="menuItem in filteredPrimaryMenu"
          :key="menuItem.toState"
          :icon="menuItem.icon"
          :name="menuItem.label"
          :to="menuItem.toState"
          :is-child-menu-active="menuItem.key === primaryActiveMenuItem"
          :view-box="menuItem.viewBox"
        />
      </div>
      <div
        ref="childEl"
        class="account-dropdown__foot"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <div class="account-dropdown__action">
          <div class="account-dropdown__action-prefix">
            <Icon
              size="18"
              view-box="0 1.3 16 16"
              icon="store"
              type="outline"
              :icons="iconLib"
              is-in-sidebar
            />
          </div>
          <div class="account-dropdown__action-text">Switch Account</div>
          <div class="account-dropdown__action-suffix">
            <fluent-icon size="16" icon="chevron-right" type="outline" />
          </div>
        </div>

        <!-- choose store dropdown -->
        <div
          v-if="isShowChildMenu"
          class="dropdown-child"
          :style="childStyle"
          @mouseenter="childHovered = true"
          @mouseleave="childHovered = false"
        >
          <!-- hover area helper -->
          <div class="dropdown-child__helper" />
          <div class="dropdown-child__content">
            <div class="dropdown-child__head">Account</div>
            <div class="dropdown-child__wrapper">
              <div
                v-for="account in currentUser.accounts"
                :id="`account-${account.id}`"
                :key="account.id"
                class="dropdown-child__body"
              >
                <div
                  class="dropdown-child__item"
                  @click="onChangeAccount(account.id)"
                >
                  <div class="dropdown-child__item-prefix">
                    <thumbnail :username="account.name" size="16px" />
                  </div>
                  <div class="dropdown-child__item-text">
                    {{ account.name }}
                  </div>
                  <div class="dropdown-child__item-suffix">
                    <div v-if="account.id === accountId">
                      <fluent-icon size="16" icon="checkmark" type="outline" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown-child__foot">
              <div class="dropdown-child__action" @click="handleAddStore">
                <fluent-icon icon="add" class="dropdown-child__action-icon" />
                <div class="dropdown-child__action-text">
                  {{ $t('CREATE_ACCOUNT.NEW_ACCOUNT') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NewLogo from './NewLogo.vue';
import NewPrimaryNavItem from './NewPrimaryNavItem.vue';
import Thumbnail from '../../widgets/Thumbnail.vue';
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/sidebar-icons.json';
import { isFirefox } from 'shared/helpers/browserCheck';

export default {
  components: {
    NewLogo,
    NewPrimaryNavItem,
    Thumbnail,
    Icon,
  },
  props: {
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    primaryMenuItems: {
      type: Array,
      default: () => [],
    },
    primaryActiveMenuItem: {
      type: String,
      default: '',
    },
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowDropdown: false,
      dropdownStyle: {
        position: 'fixed',
        top: '0px',
        left: '0px',
      },
      isShowChildMenu: false,
      childHovered: false,
      childStyle: {
        position: 'fixed',
        top: '0px',
        left: '0px',
      },
      currentStoreName: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
    }),
    getUserStore() {
      return this.currentUser?.accounts || [];
    },
    filteredPrimaryMenu() {
      return this.primaryMenuItems.filter(item => item.hidden !== true);
    },
    iconLib() {
      return icons;
    },
    isFirefox() {
      return isFirefox;
    },
  },
  watch: {
    isShowDropdown: {
      handler(newValue) {
        this.$emit('sidebar-scrollable', newValue);
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateDropdownPosition);
      window.addEventListener('resize', this.updateChildPosition);
      this.updateDropdownPosition();
    });
    window.addEventListener('mouseup', this.onOutsideClick);
    this.setCurrentStoreName();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDropdownPosition);
    window.removeEventListener('resize', this.updateChildPosition);
    window.removeEventListener('mouseup', this.onOutsideClick);
  },
  methods: {
    setCurrentStoreName() {
      const storeName = this.getUserStore.filter(
        item => item.id === this.accountId
      );
      this.currentStoreName = storeName[0].name;
    },
    handleShowDropdown() {
      this.isShowDropdown = !this.isShowDropdown;
    },
    handleCloseDropdown() {
      this.isShowDropdown = false;
    },
    onOutsideClick(e) {
      if (
        this.isShowDropdown &&
        e.target !== this.$refs.parentEl &&
        !this.$refs.parentEl.contains(e.target) &&
        !this.$refs.parentEl.contains(e.target)
      ) {
        this.handleCloseDropdown();
      }
    },
    updateDropdownPosition() {
      if (this.$refs.parentEl) {
        const parentRect = this.$refs.parentEl.getBoundingClientRect();
        this.dropdownStyle.top = `${parentRect.bottom + 8}px`;
        this.dropdownStyle.left = `${parentRect.left}px`;
      }
    },
    updateChildPosition() {
      if (this.$refs.childEl) {
        const parentRect = this.$refs.childEl.getBoundingClientRect();
        this.childStyle.top = `${parentRect.bottom - 30}px`;
        this.childStyle.left = `${parentRect.left + 250}px`;
      }
    },
    onChangeAccount(accountId) {
      const accountUrl = `/app/accounts/${accountId}/dashboard`;
      window.location.href = accountUrl;
    },
    handleMouseEnter() {
      this.isShowChildMenu = true;
      this.$nextTick(this.updateChildPosition);
    },
    handleMouseLeave() {
      setTimeout(() => {
        if (!this.childHovered) {
          this.isShowChildMenu = false;
        }
      }, 50);
    },
    handleAddStore() {
      this.isShowChildMenu = false;
      this.childHovered = false;
      this.$emit('show-create-account-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.account-dropdown {
  @apply sticky top-0 left-0 pt-2
  z-10
  bg-neutral-subtle
  dark:bg-primary;

  &__head {
    @apply flex
    items-center
    h-full
    relative
    w-full
    gap-2
    text-primary
    dark:text-primary-dark
    dark:hover:bg-[#222527]
    border
    bg-white
    dark:bg-[#222527]
    border-primary-dark
    dark:border-[#474D52]
    rounded-lg
    p-2
    min-h-[48px]
    cursor-pointer
    select-none;

    &-logo {
      width: 32px;
      height: 32px;
      position: absolute;
      top: calc(50% + 2px);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-logo-expanded {
      @apply relative top-[2.5px];
    }

    &-text {
      @apply w-[62%] text-ellipsis overflow-hidden whitespace-nowrap font-medium;
    }

    &-icon {
      @apply flex items-center justify-end w-[16px] h-[16px];
    }
  }

  &__wrapper {
    @apply w-[245px] bg-white dark:bg-[#222526] z-10 shadow-dropdown rounded-lg overflow-hidden border border-neutral-medium dark:border-neutral-dark-medium;
  }

  &__body {
    @apply max-h-[246px] overflow-y-auto px-2 pb-2;
  }

  &__title {
    @apply flex items-center h-[35px] text-secondary dark:text-secondary-dark sticky top-0 left-0 w-full bg-white dark:bg-[#222526] z-10 px-4 text-[13px];
  }

  &__foot {
    @apply flex
    items-center
    sticky
    bottom-0
    left-0
    w-full
    bg-white
    dark:bg-[#222526]
    z-10
    border-t
    border-neutral-medium
    dark:border-neutral-dark-medium;
  }

  &__action {
    @apply flex items-center leading-4 text-sm w-full cursor-pointer px-3 py-2 text-primary dark:text-primary-dark m-1 rounded-[4px] hover:bg-neutral-low dark:hover:bg-primary;
  }
  &__action-prefix {
    @apply w-[16px] h-[16px] relative mr-2;
  }
  &__action-text {
    @apply leading-none w-[80%];
  }
  &__action-suffix {
    @apply flex items-center justify-end w-[16px] h-[16px] relative ml-1.5 rtl:mr-0 rtl:ml-1.5;
  }
}

.dropdown-child {
  @apply z-10 w-[245px];

  // hover area helper
  &__helper {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -22px;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    @apply overflow-hidden rounded-lg shadow-dropdown relative bg-white dark:bg-[#222526] border border-neutral-medium dark:border-neutral-dark-medium;
  }

  &__wrapper {
    @apply w-full overflow-y-auto px-2 pb-2 max-h-[180px];
  }

  &__head {
    @apply flex items-center h-[33px] text-secondary dark:text-secondary-dark sticky top-0 left-0 w-full bg-white dark:bg-[#222526] z-10 px-4 text-[13px];
  }

  &__body {
    @apply list-none mb-0 ml-0;
  }

  &__item {
    @apply flex
    items-center
    leading-4
    text-sm w-full
    cursor-pointer
    px-3
    py-2
    text-primary
    dark:text-primary-dark
     mt-1
     rounded-[4px]
     hover:bg-neutral-low
     dark:hover:bg-primary;
  }
  &__item-prefix {
    @apply flex items-center w-[16px] h-[16px] relative mr-1.5 rtl:ml-0 rtl:mr-1.5;

    ::v-deep {
      .avatar-container.user-thumbnail {
        border-radius: 2px;
        font-size: 8px !important;
      }
    }
  }
  &__item-text {
    @apply leading-none w-[80%];
  }
  &__item-suffix {
    @apply flex items-center justify-end w-[16px] h-[16px] relative ml-1.5 rtl:mr-0 rtl:ml-1.5;
  }

  &__foot {
    @apply flex
    items-center
    sticky
    bottom-0
    left-0
    w-full
    bg-white
    dark:bg-[#222526]
    z-10
    border-t
    border-neutral-medium
    dark:border-neutral-dark-medium;
  }

  &__action {
    @apply flex items-center leading-4 text-sm w-full cursor-pointer px-3 py-2 text-accent dark:text-accent-dark m-1 rounded-[4px];

    &:hover {
      @apply bg-accent-low dark:bg-accent-dark-low;
    }
  }
  &__action-icon {
    @apply w-[16px] h-[16px] relative mr-1.5 rtl:mr-0 rtl:ml-1.5;
  }
  &__action-text {
    @apply leading-none;
  }
}
</style>
