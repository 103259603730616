<template>
  <div
    class="message-text__wrap"
    :class="{
      'show--quoted': isQuotedContentPresent,
      'hide--quoted': !isQuotedContentPresent,
    }"
  >
    <div
      v-if="!isEmail"
      v-dompurify-html="formattedMessage"
      class="text-content"
    />
    <letter v-else class="text-content" :html="formattedMessage" />
    <button
      v-if="showQuoteToggle"
      class="text-slate-300 dark:text-slate-300 cursor-pointer text-xs py-1"
      @click="toggleQuotedContent"
    >
      <span v-if="showQuotedContent" class="flex items-center gap-0.5">
        <fluent-icon icon="chevron-up" size="16" />
        {{ $t('CHAT_LIST.HIDE_QUOTED_TEXT') }}
      </span>
      <span
        v-else
        class="flex items-center gap-0.5 text-secondary dark:text-secondary-dark"
      >
        <fluent-icon icon="chevron-down" size="16" />
        {{ $t('CHAT_LIST.SHOW_QUOTED_TEXT') }}
      </span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Letter from 'vue-letter';
import { MESSAGE_TYPE } from 'shared/constants/messages';

export default {
  components: { Letter },
  props: {
    message: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    displayQuotedButton: {
      type: Boolean,
      default: false,
    },
    messageType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      showQuotedContent: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    isQuotedContentPresent() {
      if (!this.isEmail) {
        return this.message.includes('<blockquote');
      }
      return this.showQuotedContent;
    },
    showQuoteToggle() {
      if (!this.isEmail) {
        return false;
      }
      return this.displayQuotedButton;
    },
    isActivity() {
      return MESSAGE_TYPE.ACTIVITY === this.messageType;
    },
    formattedMessage() {
      if (!this.isActivity) {
        return this.message;
      }
      return this.message.replace(/#(\d+)/g, (match, p1) => {
        return `<span class="text-accent dark:text-accent cursor-pointer" data-conversation-id="${p1}">${match}</span>`;
      });
    },
  },
  mounted() {
    this.$el.addEventListener('click', this.handleMessageLinkClick);
  },
  beforeDestroy() {
    this.$el.removeEventListener('click', this.handleMessageLinkClick);
  },
  methods: {
    toggleQuotedContent() {
      this.showQuotedContent = !this.showQuotedContent;
    },
    handleMessageLinkClick(event) {
      const conversationId = event.target.getAttribute('data-conversation-id');
      if (conversationId) {
        this.$router.push(
          `/app/accounts/${this.accountId}/conversations/${conversationId}`
        );
      }
    },
  },
};
</script>
<style lang="scss">
.text-content {
  @apply text-primary dark:text-primary-dark;
  overflow: auto;

  ul,
  ol {
    padding-left: var(--space-two);
  }
  table {
    margin: 0;
    border: 0;

    td {
      margin: 0;
      border: 0;
    }

    tr {
      border-bottom: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-normal);
  }
}

.show--quoted {
  blockquote {
    @apply block;
  }
}

.hide--quoted {
  blockquote {
    @apply hidden;
  }
}
</style>
