var render = function render(){var _vm=this,_c=_vm._self._c;return _c('woot-modal',{staticClass:"instruction",attrs:{"show":_vm.showModal,"on-close":_vm.onCancel,"is-use-transition":false,"modal-type":"right-aligned"},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('div',{staticClass:"instruction__wrapper"},[_c('div',{staticClass:"instruction__head"},[_c('woot-modal-header',{staticClass:"pt-4",attrs:{"header-title":_vm.headerTitle}})],1),_vm._v(" "),_c('div',{staticClass:"instruction__body"},[_c('div',{staticClass:"mb-2 text-sm"},[_vm._v("\n        "+_vm._s(_vm.$t('SIDEBAR.AI_RESPONSE.MODAL.TITLE'))+"\n      ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],ref:"titleField",staticClass:"instruction__input",class:{
          'is-focused': _vm.focusedField === 'titleField',
        },attrs:{"type":"text","disabled":_vm.isFetching,"placeholder":_vm.isFetching ? 'Generating title...' : ''},domProps:{"value":(_vm.title)},on:{"focus":function($event){return _vm.onFocus('titleField')},"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.title=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"mb-2 text-sm"},[_vm._v("\n        "+_vm._s(_vm.$t('SIDEBAR.AI_RESPONSE.MODAL.DESCRIPTION'))+"\n      ")]),_vm._v(" "),_c('resizable-text-area',{staticClass:"instruction__textarea",class:{
          'is-focused': _vm.focusedField === 'descriptionField',
        },attrs:{"disabled":_vm.isFetching,"placeholder":_vm.isFetching ? 'Generating description...' : ''},on:{"focus":function($event){return _vm.onFocus('descriptionField')},"blur":_vm.onBlur},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_vm._v(" "),_c('div',{staticClass:"mb-2 text-sm"},[_vm._v("\n        "+_vm._s(_vm.$t('SIDEBAR.AI_RESPONSE.MODAL.RESPOND'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"instruction__editor",class:{
          'is-focused': _vm.focusedField === 'instructionField',
        }},[_c('woot-message-editor',{staticClass:"input",attrs:{"disabled":_vm.isFetching,"markdown-placeholder":_vm.$t('SIDEBAR.AI_RESPONSE.MODAL.RESPOND_PLACEHOLDER')},on:{"focus":function($event){return _vm.onFocus('instructionField')},"blur":_vm.onBlur},model:{value:(_vm.instruction),callback:function ($$v) {_vm.instruction=$$v},expression:"instruction"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"instruction__footer"},[_c('div',{staticClass:"instruction__footer-button"},[_c('woot-button',{attrs:{"variant":"clear"},on:{"click":_vm.onCancel}},[_vm._v("\n          "+_vm._s(_vm.$t('SIDEBAR.AI_RESPONSE.CANCEL'))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"instruction__footer-button"},[_c('woot-button',{attrs:{"is-disabled":_vm.isDisabled},on:{"click":_vm.onSubmit}},[_vm._v("\n          "+_vm._s(_vm.$t('SIDEBAR.AI_RESPONSE.SAVE'))+" &\n          "+_vm._s(_vm.$t('SIDEBAR.AI_RESPONSE.REWRITE'))+"\n        ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }