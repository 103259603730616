<template>
  <div
    class="app-wrapper h-full flex-grow-0 min-h-0 w-full max-w-full ml-auto mr-auto flex flex-wrap dark:text-slate-300 bg-white dark:bg-primary"
  >
    <new-sidebar
      :route="currentRoute"
      :show-secondary-sidebar="isSidebarOpen"
      @open-notification-panel="openNotificationPanel"
      @open-key-shortcut-modal="toggleKeyShortcutModal"
      @close-key-shortcut-modal="closeKeyShortcutModal"
      @show-add-label-popup="showAddLabelPopup"
      @show-add-folder-popup="showAddFolderPopup"
      @show-create-account-modal="openCreateAccountModal"
      @toggle-account-modal="toggleAccountModal"
    />
    <section class="flex h-full min-h-0 overflow-hidden flex-1 px-0">
      <router-view />
      <command-bar />
      <account-selector
        :show-account-modal="showAccountModal"
        @close-account-modal="toggleAccountModal"
        @show-create-account-modal="openCreateAccountModal"
      />
      <add-account-modal
        :show="showCreateAccountModal"
        @close-account-create-modal="closeCreateAccountModal"
      />
      <woot-key-shortcut-modal
        :show.sync="showShortcutModal"
        @close="closeKeyShortcutModal"
        @clickaway="closeKeyShortcutModal"
      />
      <notification-panel
        v-if="isNotificationPanel"
        :is-sidebar-expanded="isSidebarOpen"
        @close="closeNotificationPanel"
      />
      <woot-modal :show.sync="showAddLabelModal" :on-close="hideAddLabelPopup">
        <add-label-modal @close="hideAddLabelPopup" />
      </woot-modal>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NewSidebar from '../../components/layout/NewSidebar.vue';
import CommandBar from './commands/commandbar.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal.vue';
import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal.vue';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector.vue';
import AddLabelModal from 'dashboard/routes/dashboard/settings/labels/AddLabel.vue';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel.vue';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import wootConstants from 'dashboard/constants/globals';
import { getSidebarItems } from 'dashboard/components/layout/config/default-sidebar';

export default {
  components: {
    CommandBar,
    WootKeyShortcutModal,
    AddAccountModal,
    AccountSelector,
    AddLabelModal,
    NotificationPanel,
    NewSidebar,
  },
  mixins: [uiSettingsMixin],
  data() {
    return {
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false,
      showShortcutModal: false,
      isNotificationPanel: false,
      displayLayoutType: '',
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    currentRoute() {
      return ' ';
    },
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    previouslyUsedDisplayType() {
      const {
        previously_used_conversation_display_type: conversationDisplayType,
      } = this.uiSettings;
      return conversationDisplayType;
    },
    previouslyUsedSidebarView() {
      const { previously_used_sidebar_view: showSecondarySidebar } =
        this.uiSettings;
      return showSecondarySidebar;
    },
    sideMenuConfig() {
      return getSidebarItems(this.accountId);
    },
    getSecondaryMenuRouteNames() {
      // get the route name of secondary menu in homepage
      const { secondaryMenu } = this.sideMenuConfig;
      const activeSecondaryMenu = secondaryMenu.find(
        menuItem => menuItem.routes
      );
      return activeSecondaryMenu?.routes;
    },
    isInHomepage() {
      const currentRouteName = this.$route.name;
      return this.getSecondaryMenuRouteNames.includes(currentRouteName);
    },
  },
  watch: {
    displayLayoutType() {
      const { LAYOUT_TYPES } = wootConstants;
      this.updateUISettings({
        conversation_display_type:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? LAYOUT_TYPES.EXPANDED
            : this.previouslyUsedDisplayType,
        show_secondary_sidebar:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? false
            : this.previouslyUsedSidebarView,
      });
    },
    '$route.name'() {
      // if not in homepage, force the sidebar to open
      this.forceSidebarToOpen();
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    bus.$on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);

    // if not in homepage, force the sidebar to open
    this.$nextTick(() => {
      if (document.readyState === 'complete') {
        this.forceSidebarToOpen();
      } else {
        window.addEventListener('load', this.forceSidebarToOpen);
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    bus.$off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },
  methods: {
    handleResize() {
      // disable sidebar auto collapse when not in homepage
      if (!this.isInHomepage) {
        return;
      }

      const { SMALL_SCREEN_BREAKPOINT, LAYOUT_TYPES } = wootConstants;
      let throttled = false;
      const delay = 150;

      if (throttled) {
        return;
      }
      throttled = true;

      setTimeout(() => {
        throttled = false;
        if (window.innerWidth <= SMALL_SCREEN_BREAKPOINT) {
          this.displayLayoutType = LAYOUT_TYPES.EXPANDED;
        } else {
          this.displayLayoutType = LAYOUT_TYPES.CONDENSED;
        }
      }, delay);
    },
    toggleSidebar() {
      this.updateUISettings({
        show_secondary_sidebar: !this.isSidebarOpen,
        previously_used_sidebar_view: !this.isSidebarOpen,
      });
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },
    showAddLabelPopup() {
      this.showAddLabelModal = true;
    },
    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },
    showAddFolderPopup() {
      bus.$emit('toggle-advance-filters-modal');
    },
    openNotificationPanel() {
      this.isNotificationPanel = true;
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
    forceSidebarToOpen() {
      if (!this.isInHomepage) {
        this.updateUISettings({
          show_secondary_sidebar: true,
        });
      } else {
        // use previous sidebar view if not in homepage
        this.updateUISettings({
          show_secondary_sidebar: this.previouslyUsedSidebarView,
        });
      }
    },
  },
};
</script>
