<template>
  <woot-modal
    :show.sync="showModal"
    :on-close="onCancel"
    :is-use-transition="false"
  >
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('CONVERSATION.MERGE_CONFIRMATION.TITLE')"
      />
      <div
        class="text-sm leading-6 w-full flex-1 py-4 px-8 overflow-y-auto text-primary dark:text-primary-dark"
      >
        {{ $t('CONVERSATION.MERGE_CONFIRMATION.QUESTION') }}
        <span class="text-secondary dark:text-secondary-dark">
          {{ $t('CONVERSATION.MERGE_CONFIRMATION.DESCRIPTION') }}
        </span>
      </div>
      <div class="flex flex-row justify-end gap-2 py-4 px-6 w-full">
        <woot-button variant="clear" @click="onCancel">
          {{ $t('CONVERSATION.MERGE_CONFIRMATION.CANCEL') }}
        </woot-button>
        <woot-button :is-loading="isLoading" @click="onMerge">
          <template v-if="isLoading">
            {{ $t('CONVERSATION.MERGE_CONFIRMATION.WAIT') }}
          </template>
          <template v-else>
            {{ $t('CONVERSATION.MERGE_CONFIRMATION.MERGE') }}
          </template>
        </woot-button>
      </div>
    </div>
  </woot-modal>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: this.show,
    };
  },
  watch: {
    async show(newVal) {
      this.showModal = newVal;
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onMerge() {
      this.$emit('submit');
    },
  },
};
</script>
